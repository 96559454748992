import api, { apiConfig } from 'constants/api'
import { takeLatest, put, call, select, delay, all } from 'redux-saga/effects'
import FileDownload from 'js-file-download'
import { DISPLAY_PER_PAGE } from 'constants/user-venues'
import { userVenuesList } from 'sagas/selectors/user'
import { userVenuesBuilderForUI } from 'mappers/users'
import userActions from 'actions/users/actions'
import * as userTypes from 'actions/users/types'
import * as messageTypes from 'actions/messages/types'
import * as notifTypes from 'actions/notifications/types'
import Cookies from 'js-cookie'
import formSelector from './selectors/form'

const apiClient = apiConfig({ baseURL: '/api/client' })
const apiAdmin = apiConfig({ baseURL: '/api/admin' })

const sortCompare = (key) => (a, b) => {
  if (a[key] < b[key]) {
    return -1
  }
  if (a[key] > b[key]) {
    return 1
  }
  return 0
}

function* detailsFetchVenues({ payload }) {
  try {
    yield delay(300)
    const { id, limit, page = 1, isFirstFetch = true } = payload
    const { data } = yield call(api.get, `/users/${id}/venues`, {
      params: { page, size: limit },
    })
    yield put({
      type: userTypes.DETAILS_FETCHED_VENUE,
      data: { ...data, isFirstFetch },
    })
  } catch (error) {
    console.log(error)
  }
}

function* detailsFetchSpaces({ payload }) {
  try {
    yield delay(300)
    const { id, limit, page } = payload
    const { data } = yield call(api.get, `/users/${id}/spaces`, {
      params: { page, size: limit },
    })
    yield put({
      type: userTypes.DETAILS_FETCHED_SPACES,
      data: { ...data, isFirstFetch: payload.isFirstFetch },
    })
  } catch (error) {
    console.log(error)
  }
}

function* detailsFetchLead({ payload }) {
  try {
    const { id, limit, page } = payload
    const { data } = yield call(api.get, `/users/${id}/leads`, {
      params: { page, size: limit },
    })
    yield put({ type: userTypes.DETAILS_FETCHED_LEAD, data })
  } catch (error) {
    console.log(error)
  }
}

function* detailsFetchBooking({ payload }) {
  try {
    const { id, limit, page } = payload
    const { data } = yield call(api.get, `/users/${id}/bookings`, {
      params: { page, size: limit },
    })
    yield put({ type: userTypes.DETAILS_FETCHED_BOOKING, data })
  } catch (error) {
    console.log(error)
  }
}

function* getUsers({ payload }) {
  try {
    const statePage = yield select((state) => state.user.page)
    const stateLimit = yield select((state) => state.user.limit)
    const { limit = stateLimit, page = statePage } = payload

    const selector = yield formSelector('admin-users-filter-form')
    const keywordFilter = yield select((state) => selector(state, 'admin_search_filter'))
    // @ todo support for multiselect
    let roleFilter = yield select((state) => selector(state, 'type_filter'))
    roleFilter = roleFilter && roleFilter.map((a) => a.value)
    let placeFilter = yield select((state) => selector(state, 'admin_place_filter'))
    placeFilter = placeFilter && placeFilter.map((a) => a.value)
    const statusFilter = yield select((state) => selector(state, 'status_filter'))
    let merchantFilter = yield select((state) => selector(state, 'subscription_filter'))
    merchantFilter = merchantFilter && merchantFilter.map((a) => a.value)

    const { data } = yield call(api.get, '/users', {
      params: {
        limit,
        page,
        keywordFilter,
        roleFilter,
        placeFilter,
        statusFilter,
        merchantFilter,
      },
    })

    yield delay(300) // to simulate tableloader
    yield put({ type: userTypes.FETCHED_USERS, response: data.users })
  } catch (response) {
    if (response) console.error(response.message)
  }
}

function* getUser({ payload }) {
  try {
    const { data } = yield call(api.get, `/users/${payload}`)
    data.userInfo.deactivate = data.userInfo.status === 'deactivated'
    if (data.userInfo.brand_logo) {
      data.userInfo.brand_logo = [
        {
          path: data.userInfo.brand_logo,
          name: data.userInfo.brand_logo,
        },
      ]
    }
    console.log('getUser', data)
    yield put({ type: userTypes.FETCHED_USER, user: data.userInfo })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: response.data.error })
  }
}

function* deleteUser({ payload }) {
  try {
    yield call(api.delete, `/users/${payload}`)
    yield call(getUsers, { payload: {} })
  } catch (error) {
    console.log(error)
  }
}

function* getUserVenues({ payload = {} }) {
  const { page: pageNumber = 1 } = payload
  try {
    const { data: result } = yield call(api.get, `/user/venues/${pageNumber}`)
    const { data, page, lastPage } = result

    const oldList = yield select(userVenuesList)
    const newList = data.map(userVenuesBuilderForUI)

    const list = [...new Set(page > 1 ? [...oldList, ...newList] : newList)].map((v, idx, arr) => {
      const onePager = arr.length < DISPLAY_PER_PAGE
      const noMore = page === lastPage
      const isLastItem = idx === arr.length - 1
      return {
        ...v,
        isShow: !isLastItem || (isLastItem && (onePager || noMore)),
      }
    })

    yield put(userActions.fetchedUserVenues({ list, page, lastPage }))
  } catch (error) {
    error && console.error(error.message, 'here')
    yield put(userActions.fetchedUserVenues({ list: [] }))
  }
}

function* deleteVenue({ payload }) {
  try {
    const { id, user_id, limit } = payload
    yield call(apiAdmin.delete, `/venues/${id}`)
    yield put({ type: notifTypes.SUCCESS, message: 'Delete venue success' })
    yield call(detailsFetchVenues, { payload: { id: user_id, limit } })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: response?.data ?? 'Error delete venue!' })
  }
}

// @TODO: city not being saved and merchant not saving company name
function* createUser({ payload }) {
  const { formName } = payload
  try {
    const { data } = yield call(api.post, '/users', {
      ...payload,
    })

    yield all([put({ type: notifTypes.SUCCESS, ...data }), put({ type: userTypes.CREATED_USER })])
  } catch ({ message, response }) {
    const errors = { errors: response?.data || [], formName }
    yield put({
      type: notifTypes.ERROR,
      message: message ?? 'Error create user.',
      payload: errors,
    })
  }
}

function* editUser({ payload }) {
  const { formName } = payload
  try {
    console.log(payload)
    if (payload.brand_logo && payload.brand_logo.length > 0) {
      payload.brand_logo = `${payload.brand_logo[0].path.split('?')[0]}?o=true`
    }
    const { data } = yield call(api.put, `/users/${payload.userID}`, {
      ...payload,
    })

    yield all([put({ type: notifTypes.SUCCESS, ...data }), put({ type: userTypes.CREATED_USER })])
  } catch ({ message, response }) {
    const errors = { errors: response?.data || [], formName }
    yield put({
      type: notifTypes.ERROR,
      message: message ?? 'Error create user.',
      payload: errors,
    })
  }
}

function* getPassportSubscription({ payload }) {
  try {
    const { data } = yield call(apiClient.get, `passport-subscription/${payload}`)

    console.log('getPassportSubscription', data)
    yield put({
      type: userTypes.FETCHED_USER_PASSPORT_SUBSCRIPTION,
      subscription: data.subscription,
    })
  } catch (error) {
    console.log(error)
  }
}

function* cancelPassportSubscription({ payload }) {
  try {
    const { data } = yield call(api.delete, `passport-subscriptions/${payload}`)
    yield all([
      put({ type: userTypes.CANCELLED_USER_PASSPORT_SUBSCRIPTION }),
      put({ type: notifTypes.SUCCESS, ...data }),
    ])
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* sortUserList({ payload }) {
  try {
    const { key } = payload
    const list = yield select((state) => state.user.list)
    const newList = list.sort(sortCompare(key))
    yield put({ type: userTypes.SORTED_USER_LIST, list: newList })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

// ADMIN
function* searchUserByNameOrEmail(action) {
  try {
    yield delay(500)
    const { payload, resolve } = action
    const { data } = yield call(apiAdmin.post, `/user/quick-search`, payload)
    resolve(data)
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* assignConversation({ payload }) {
  try {
    yield call(apiAdmin.post, `/conversations/assign-conversation`, payload)
    yield put({ type: notifTypes.SUCCESS, message: `Assign Successfully` })
    yield put({ type: messageTypes.GET_MESSAGES, payload: payload.conversation_id })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: 'Error assign conversation.' })
  }
}

function* loginAsUser({ payload, resolve }) {
  try {
    const email = yield select((state) => state.auth.user.email)
    const { data } = yield call(apiAdmin.post, `/login-as/${payload}`)
    console.log(`data`)
    console.log(data)
    Cookies.set('adminLoginAsInfo', { email, code: data.code, userId: data.user.id })
    resolve && resolve({})
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: 'Error login as user.' })
  }
}

function* switchToAdmin({ payload, resolve }) {
  try {
    const { data } = yield call(api.post, '/switch-user', payload)
    console.log(data)
    Cookies.set('adminLoginAsInfo', {})
    resolve && resolve({})
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: 'Error login as user.' })
  }
}

function* exportUsers() {
  try {
    const response = yield call(apiAdmin.get, '/export-users', { responseType: 'arraybuffer' })
    FileDownload(response.data, 'users.xlsx')
    yield put({ type: userTypes.EXPORTED_USERS })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: 'Error Export Users' })
  }
}

export default function* () {
  yield takeLatest(userTypes.SWITCH_TO_ADMIN, switchToAdmin)
  yield takeLatest(userTypes.LOGIN_AS_USER, loginAsUser)
  yield takeLatest(userTypes.ASSIGN_CONVERSATION, assignConversation)
  yield takeLatest(userTypes.FETCH_USERS, getUsers)
  yield takeLatest(userTypes.DETAILS_FETCH_VENUE, detailsFetchVenues)
  yield takeLatest(userTypes.DETAILS_FETCH_SPACES, detailsFetchSpaces)
  yield takeLatest(userTypes.DETAILS_FETCH_LEAD, detailsFetchLead)
  yield takeLatest(userTypes.DETAILS_FETCH_BOOKING, detailsFetchBooking)
  yield takeLatest(userTypes.ADMIN_SEARCH_USER, searchUserByNameOrEmail)
  yield takeLatest(userTypes.DELETE_USER, deleteUser)
  yield takeLatest(userTypes.DELETE_VENUE, deleteVenue)
  yield takeLatest(userTypes.SORT_USER_LIST, sortUserList)
  yield takeLatest(userTypes.FETCH_USER, getUser)
  yield takeLatest(userTypes.FETCH_USER_VENUES, getUserVenues)
  yield takeLatest(userTypes.CREATE_USER, createUser)
  yield takeLatest(userTypes.EDIT_USER, editUser)
  yield takeLatest(userTypes.FETCH_USER_PASSPORT_SUBSCRIPTION, getPassportSubscription)
  yield takeLatest(userTypes.CANCEL_USER_PASSPORT_SUBSCRIPTION, cancelPassportSubscription)
  yield takeLatest(userTypes.EXPORT_USERS, exportUsers)
}
