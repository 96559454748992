import api, { apiConfig } from 'constants/api'
import { takeLatest, put, call, takeEvery } from 'redux-saga/effects'
import * as types from 'actions/venues/types'
import * as notifTypes from 'actions/notifications/types'
import moment from 'moment'

const apiAdmin = apiConfig({ baseURL: '/api/admin' })

const apiMerchant = apiConfig({ baseURL: '/api/merchant' })

const compareSchedule = (a, b) => {
  if (a.day_in_week < b.day_in_week) {
    return -1
  }
  if (a.day_in_week > b.day_in_week) {
    return 1
  }
  return 0
}

function* searchVenues({ payload, resolve }) {
  try {
    console.log(payload)
    const { data } = yield call(api.get, `venues/quick-search`, {
      params: payload,
    })
    resolve(data.data)
  } catch ({ message }) {
    console.error(message)
  }
}

function* fetchVenueFeatured({ payload }) {
  try {
    // console.log(`todo fetchVenueFeatured: ${payload}`)
    const { cityId, categoryId, page, brandId, city } = payload
    const { data } = yield call(api.get, `/venues/featured-homepage`, {
      params: {
        cityId,
        categoryId,
        brandId,
        city,
      },
    })
    const { results } = data
    yield put({ type: types.FETCHED_VENUE_FEATURED, list: results, page })
  } catch ({ response }) {
    if (response) console.error(response.message)
  }
}

function* fetchVenue({ payload }) {
  try {
    const { data } = yield call(api.get, `/venue/${payload}`)
    const venueInfo = data
    venueInfo.reviews = venueInfo.reviews.sort((a, b) => b.id - a.id)
    const { reviews = [] } = venueInfo
    const reviewsFormat = reviews.map((review) => {
      return {
        ...review,
        date: moment(review.date).format('MMMM YYYY'),
      }
    })
    yield put({ type: types.FETCHED_VENUE, venueInfo: { ...venueInfo, reviews: reviewsFormat } })
  } catch ({ response }) {
    if (response) console.error(response.message)
  }
}

function* fetchVenueList({ payload }) {
  try {
    const { cityId, categoryId, limit, page } = payload

    const { data } = yield call(api.get, '/venues', {
      params: {
        cityId,
        categoryId,
        limit,
      },
    })
    const { results } = data
    yield put({ type: types.FETCHED_VENUE_LIST, list: results, page })
  } catch ({ response }) {
    if (response) console.error(response.message)
  }
}

function* merchantFetchVenueDetails({ payload: { id } }) {
  try {
    const { data } = yield call(apiMerchant.get, `/venues/${id}`)
    const details = data.venue
    details.latLng = {
      lat: details.lat,
      address: details.address,
      lng: details.lng,
    }
    details.schedulesOpen = details.schedules.sort(compareSchedule)
    yield put({ type: types.FETCHED_VENUE_DETAILS, details })
  } catch (err) {
    console.warn(err)
  }
}

function* createVenue({ payload }) {
  const { formName } = payload
  try {
    payload.lat = payload?.latLng?.lat
    payload.lng = payload?.latLng?.lng
    payload.address = payload?.latLng?.address
    payload.landmark_ids = (payload.landmarks || []).map((l) => l.value)
    let schedules = payload.schedulesOpen
    schedules = schedules?.map((schedule, index) => ({ ...schedule, day_in_week: index + 1 })) ?? []
    if (!payload.differentSchedule) {
      schedules = [
        { ...schedules[4], day_in_week: 1 },
        { ...schedules[4], day_in_week: 2 },
        { ...schedules[4], day_in_week: 3 },
        { ...schedules[4], day_in_week: 4 },
        { ...schedules[4], day_in_week: 5 },
        { ...schedules[5], day_in_week: 6 },
        { ...schedules[6], day_in_week: 0 },
      ]
    }
    payload.schedules = schedules
    const { data } = yield call(apiMerchant.post, 'venues', payload)
    yield put({ type: types.CREATED_VENUE, venue: { id: data.id } })
    yield put({ type: notifTypes.SUCCESS, message: 'Success create venue.' })
    yield put({ type: types.MERCHANT_FETCH_VENUE_DETAILS, payload: { id: data.id } })
  } catch ({ message, response }) {
    const errors = { errors: response?.data || [], formName }
    yield put({ type: types.CREATE_EDIT_VENUE_FAILED })
    yield put({
      type: notifTypes.ERROR,
      message: 'Error create venue.',
      payload: errors,
    })
  }
}

function* editVenue({ payload }) {
  const { formName } = payload
  try {
    payload.landmark_ids = (payload.landmarks || []).map((l) => l.value)

    let schedules = payload.schedulesOpen
    schedules = schedules
      .slice(0, 7)
      .map((schedule, index) => ({ ...schedule, day_in_week: index === 6 ? 0 : index + 1 }))
    if (!payload.differentSchedule) {
      schedules = [
        { ...schedules[4], day_in_week: 1 },
        { ...schedules[4], day_in_week: 2 },
        { ...schedules[4], day_in_week: 3 },
        { ...schedules[4], day_in_week: 4 },
        { ...schedules[4], day_in_week: 5 },
        { ...schedules[5], day_in_week: 6 },
        { ...schedules[6], day_in_week: 0 },
      ]
    }
    payload.schedules = schedules

    yield call(apiMerchant.put, `/venues/${payload.id}`, payload)
    yield put({ type: types.EDITED_VENUE })
    yield put({ type: types.MERCHANT_FETCH_VENUE_DETAILS, payload })
    yield put({ type: notifTypes.SUCCESS, message: 'Success edit venue.' })
  } catch ({ message, response }) {
    const errors = { errors: response?.data || [], formName }
    let errorMessage = ''
    if (Array.isArray(errors.errors)) {
      errors.errors.forEach((er) => {
        errorMessage += `${er?.message}\n`
      })
    } else {
      errorMessage = errors.errors.message
    }
    yield put({ type: types.CREATE_EDIT_VENUE_FAILED })
    yield put({
      type: notifTypes.ERROR,
      message: errorMessage || 'Error edit venue.',
      payload: errors,
    })
  }
}

// ADMIN
function* fetchVenueDetails({ payload: { id } }) {
  try {
    const { data } = yield call(apiAdmin.get, `/venues/${id}`)
    data.latLng = {
      lat: data.lat,
      address: data.address,
      lng: data.lng,
    }
    // todo maybe need refactor data
    data.schedulesOpen = data.schedules.sort(compareSchedule)
    yield put({ type: types.FETCHED_VENUE_DETAILS, details: data })
  } catch (err) {
    console.warn(err)
  }
}

function* adminCreateVenue({ payload }) {
  const { formName } = payload
  try {
    payload.lat = payload?.latLng?.lat ?? undefined
    payload.lng = payload?.latLng?.lng ?? undefined
    payload.address = payload?.latLng?.address ?? undefined
    payload.is_discount = payload.is_discount || false
    payload.approved = payload.approved || false
    payload.published = payload.published || false
    payload.is_featured_listing = payload.is_featured_listing || false
    payload.is_featured_homepage = payload.is_featured_homepage || false
    payload.contacts = payload.contacts || []
    let schedules = payload.schedulesOpen
    schedules =
      schedules?.map((schedule, index) => ({
        ...schedule,
        day_in_week: index === 6 ? 0 : index + 1,
      })) ?? []
    if (!payload.differentSchedule) {
      schedules = [
        { ...schedules[0], day_in_week: 1 },
        { ...schedules[0], day_in_week: 2 },
        { ...schedules[0], day_in_week: 3 },
        { ...schedules[0], day_in_week: 4 },
        { ...schedules[0], day_in_week: 5 },
        { ...schedules[1], day_in_week: 6 },
        { ...schedules[2], day_in_week: 0 },
      ]
    }
    payload.schedules = schedules
    if (payload.landmarks && payload.landmarks.length)
      payload.landmark_ids = payload.landmarks.map((l) => l.value)
    yield call(apiAdmin.post, `/venues`, payload)
    yield put({ type: notifTypes.SUCCESS, message: 'Success create venue.' })
  } catch ({ message, response }) {
    const errors = { errors: response?.data || [], formName }
    yield put({
      type: notifTypes.ERROR,
      message: 'Error create venue.',
      payload: errors,
    })
  }
}

function* adminEditVenue({ payload }) {
  const { formName } = payload
  try {
    if (payload.landmarks && payload.landmarks.length)
      payload.landmark_ids = payload.landmarks.map((l) => l.value)
    let schedules = payload.schedulesOpen
    schedules = schedules
      .slice(0, 7)
      .map((schedule, index) => ({ ...schedule, day_in_week: index === 6 ? 0 : index + 1 }))
    if (!payload.differentSchedule) {
      schedules = [
        { ...schedules[4], day_in_week: 1 },
        { ...schedules[4], day_in_week: 2 },
        { ...schedules[4], day_in_week: 3 },
        { ...schedules[4], day_in_week: 4 },
        { ...schedules[4], day_in_week: 5 },
        { ...schedules[5], day_in_week: 6 },
        { ...schedules[6], day_in_week: 0 },
      ]
    }
    payload.schedules = schedules
    payload.lat = payload.latLng.lat
    payload.lng = payload.latLng.lng
    payload.address = payload.latLng.address
    yield call(apiAdmin.put, `/venues/${payload.id}`, payload)
    yield put({ type: notifTypes.SUCCESS, message: 'Success edit venue.' })
  } catch ({ message, response }) {
    const errors = { errors: response?.data || [], formName }
    yield put({
      type: notifTypes.ERROR,
      message: 'Error update venue.',
      payload: errors,
    })
  }
}

export default function* () {
  yield takeLatest(types.FETCH_VENUE_FEATURED, fetchVenueFeatured)
  yield takeLatest(types.FETCH_VENUE, fetchVenue)
  yield takeLatest(types.SEARCH_VENUES, searchVenues)
  yield takeLatest(types.MERCHANT_FETCH_VENUE_DETAILS, merchantFetchVenueDetails)
  yield takeLatest(types.FETCH_VENUE_DETAILS, fetchVenueDetails)
  yield takeLatest(types.CREATE_VENUE, createVenue)
  yield takeLatest(types.ADMIN_CREATE_VENUE, adminCreateVenue)
  yield takeLatest(types.ADMIN_EDIT_VENUE, adminEditVenue)
  yield takeLatest(types.EDIT_VENUE, editVenue)
  yield takeEvery(types.FETCH_VENUE_LIST, fetchVenueList)
}
