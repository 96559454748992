export const FETCH_VENUE = 'fetchVenue'
export const FETCHED_VENUE = 'fetchedVenue'
export const FETCHED_VENUE_LIST = 'fetchedVenueList'
export const FETCH_VENUE_LIST = 'fetchVenueList'
export const RESET_DETAILS = 'resetDetails'
export const VENUE_DISABLE_LOADING = 'venueDisableLoading'
export const FETCH_VENUE_FEATURED = 'fetchVenueFeatured'
export const FETCHED_VENUE_FEATURED = 'fetchedVenueFeatured'

export const FETCH_VENUES = 'fetchVenues'
export const FETCHED_VENUES = 'fetchedVenues'
export const FETCH_VENUE_DETAILS = 'fetchVenueDetails'
export const FETCHED_VENUE_DETAILS = 'fetchedVenueDetails'
export const CREATE_VENUE = 'createVenue'
export const CREATED_VENUE = 'createdVenue'
export const CREATE_EDIT_VENUE_FAILED = 'createEditVenueFailed'
export const EDIT_VENUE = 'editVenue'
export const EDITED_VENUE = 'editedVenue'
export const SET_ACTION = 'setAction'
export const MERCHANT_FETCH_VENUE_DETAILS = 'merchantFetchVenueDetails'
export const SEARCH_VENUES = 'searchVenues'

export const ADMIN_CREATE_VENUE = 'adminCreateVenue'
export const ADMIN_EDIT_VENUE = 'adminEditVenue'
